/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {OVERTIME_STATE as STATE} from '../../redux/overtimeHistory/overtime.types';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Button,
  Grid,
  Avatar,
  Divider,
  Paper,
  CardMedia,
  Typography,
  Link,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from '@material-ui/core';
import {
  CheckCircleOutline,
  Search,
  Refresh,
  CheckCircle,
  Cancel,
  Send,
  SaveAlt,
} from '@material-ui/icons';
import {toast, isObject, convDate, getDay} from '../../helpers';
import {
  MInput,
  MKeyBoardDatepicker,
  MButton,
  ModalTitle,
  MSelect,
  MDatepicker,
  MKeyboardTimePicker,
} from '../../components/form';
import {Datatable} from '../../components';
import _ from 'lodash';
import {
  getOvertimeHistory,
  putChangeOvertimeStatus,
  exportExcel,
} from './../../redux/overtimeHistory/overtime.actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {checkStatus} from '../../redux/organization/organization.actions';
import moment from 'moment';
import {Checkbox, FormControlLabel} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TimelineContent from '@mui/lab/TimelineContent';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';

class OvertimeHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      query: null,
      startDate: null,
      endDate: null,
      sortBy: null,
      order: 'desc',
      method: 'all',
      showDetail: false,
      showReject: false,
      showApprove: false,
      resetDatatable: null,
      detailData: null,
      resetPage: null,
      status: 'waiting',
      searchBy: null,
      reason: '',
      selectedStatus: 'waiting',
      formData: {
        date: null,
        todo: null,
        check_in: null,
        check_out: null,
        status: '',
        reason: '',
        approval_history: null,
      },
      errorMessage: null,
      errorFilterDate: false,
      checkboxFilter: false,
      timelineAccordion: false,
    };
  }

  checkboxTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  })

  tooltipTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '18px !important',
            marginLeft: '6px !important',
            marginTop: '-1px !important',
          },
        },
      },
    },
  })

  timelineTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
      secondary: {
        main: '#FFC226',
      },
      warning: {
        main: '#F00B38',
      },
      success: {
        main: '#008000',
      },
    },
    components: {
      MuiTimelineItem: {
        styleOverrides: {
          root: {
            minHeight: '46px !important',
          },
        },
      },
      MuiTimelineContent: {
        styleOverrides: {
          root: {
            padding: '6px 4px 6px 12px !important',
          },
        },
      },
      MuiTimeline: {
        styleOverrides: {
          root: {
            padding: '6px 16px 0px 16px !important',
          },
        },
      },
    },
  });

  accordionTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '0px !important',
            margin: '13px 0px 8px 0px !important',
          },
          // expanded: {
          //   // display: 'flex',
          //   justifyContent: 'space-between',
          //   minHeight: '0px !important',
          //   // margin: '8px 0px 0px 0px !important',
          // },
          root: {
            'minHeight': '0px !important',
            '&.Mui-expanded': {
              'justifyContent': 'space-between',
              'minHeight': '0px !important',
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            display: '',
            padding: '0px !important',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: '8px 4px 8px 12px !important',
            marginTop: '6px !important',
          },
        },
      },
    },
  });

  UNSAFE_componentWillMount() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const name = url.searchParams.get('query');

    if (name) {
      this.setState({query: name}, () => {
        this.handleDebounceSearch(name);
      });
    } else {
      this.fetchAttendance();
    }
  }

  componentDidMount() {
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      trigger,
      isError,
      message,
      isLoadingPost,
      isSuccessPost,
      status,
      exportData,
    } = nextProps;

    if (trigger === STATE.EXPORT_SUCCESS && isSuccessPost && exportData) {
      toast('success', 'File successfully exported & downloaded');
    }

    if (isError && message && !isObject(message)) toast('error', message);

    if (trigger === STATE.CHANGE_STATUS_OVERTIME_SUCCESS && isSuccessPost) {
      if (status === 'approved') {
        toast('success', 'Successfuly Approved Overtime');
      } else if (status === 'rejected') {
        toast('success', 'Successfuly Rejected Overtime');
      }

      this.handleResetForm(true);
      this.fetchAttendance();
      this.props.checkStatus();
    }
  }

  fetchAttendance = () => {
    const {
      page,
      limit,
      startDate,
      endDate,
      query,
      sortBy,
      order,
      status,
      searchBy,
    } = this.state;
    this.props.getOvertimeHistory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      method: 'overtime',
      ...(status && status !== 'all' && {status}),
      ...(searchBy && searchBy !== 'all' && {searchBy}),
    });
  };

  toggleModal = (type, data) => {
    const {overtimeData} = this.props;
    if (type === 'detail') {
      const numberId = data.rowData[0]; // get detail attendance from datatable response, based on index
      const selected = overtimeData.filter((data) => data.no === numberId);
      const detailData = selected && selected.length ? selected[0] : null;
      const check_in = detailData.check_in ? detailData.check_in.split(':') : null;
      const check_out = detailData.check_out ? detailData.check_out.split(':') : null;
      this.setState({
        formData: {
          date: new Date(detailData.date),
          todo: detailData.todo,
          check_in: check_in ? check_in[0]+':'+check_in[1] : null,
          check_out: check_out ? check_out[0]+':'+check_out[1] : null,
          status: detailData.status,
          reason: detailData.rejected_reason,
          approval_history: detailData.approval_history,
        },
        showDetail: true,
        detailData,
        errorMessage: {},
        selectedStatus: detailData.status,
      });
      this.setState({});
    } else if (type === 'close') {
      this.setState({showDetail: false, detailData: {...this.state.detailData}, showReject: false, showApprove: false, errorMessage: null});
    }
  };

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: false}),
    );
  };

  handleReset = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      startDate: convDate(moment()),
      endDate: convDate(moment()),
      sortBy: null,
      order: 'desc',
      method: 'all',
      status: 'all',
      searchBy: 'submission_date',
      detailData: null,
      errorFilterDate: false,
    };
    this.setState(state, () => this.fetchAttendance());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  };

  handleExport = () => {
    const {
      page,
      limit,
      startDate,
      endDate,
      query,
      sortBy,
      order,
      status,
      searchBy,
    } = this.state;
    this.props.exportExcel({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(status && status !== 'all' && {status}),
      ...(searchBy && searchBy !== 'all' && {searchBy}),
      method: 'overtime',
    });
  }

  handleReload = (params) => {
    this.setState(params, () => this.fetchAttendance());
  };

  handleButtonFilter = () => {
    const {
      page,
      limit,
      startDate,
      endDate,
      query,
      sortBy,
      order,
      status,
      searchBy,
    } = this.state;

    if (startDate === null || endDate === null) {
      return this.setState({
        errorFilterDate: true,
      });
    }

    this.props.getOvertimeHistory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      method: 'overtime',
      ...(status && status !== 'all' && {status}),
      ...(searchBy && searchBy !== 'all' && {searchBy}),
    });
  }

  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value, page: null});
  };

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState(
        {[name]: value, page: null},
    );
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearch(value);
  };

  handleFilterCheckbox = (event) => {
    const {checked} = event.target;
    if (checked) {
      this.setState({
        filterCheckbox: checked,
        startDate: convDate(moment()),
        endDate: convDate(moment()),
      });

      const state = {
        page: 1,
        limit: 10,
        query: '',
        order: 'desc',
        method: 'all',
        errorFilterDate: false,
        status: 'all',
        searchBy: 'submission_date',
      };
      this.setState(state, () => this.fetchAttendance());
      this.toggleResetDatatable(true);
      this.toggleResetPage();
    } else {
      this.setState({
        filterCheckbox: checked,
        startDate: convDate(moment()),
        endDate: convDate(moment()),
      });
      const state = {
        page: 1,
        limit: 10,
        query: '',
        startDate: null,
        endDate: null,
        sortBy: null,
        order: 'desc',
        method: 'all',
        complete: null,
        detailData: {...this.state.detailData},
        errorFilterDate: false,
        status: 'waiting',
        searchBy: null,
      };
      this.setState(state, () => this.fetchAttendance());
      this.toggleResetDatatable(true);
      this.toggleResetPage();
    }
  }

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState({page: null}, () => this.fetchAttendance());
  }, 500);

  handleInputChange = (event) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = event.target;
    this.setState({
      formData: {...formData, [name]: value},
      errorMessage: {...errorMessage, [name]: null},
    });
  };

  handleDateChange = (params) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = params;
    this.setState({
      formData: {...formData, [name]: value},
      errorMessage: {...errorMessage, [name]: null},
    });
  };

  handleCheckInChange = (params) => {
    const {value} = params;
    const selectedTime = moment(value).format('HH:mm');
    const {formData, errorMessage} = this.state;
    const {check_out} = formData;
    if (selectedTime > check_out) {
      this.setState({
        formData: {
          ...this.state.formData,
          check_in: selectedTime,
          check_out: selectedTime,
        },
        errorMessage: errorMessage,
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          check_in: selectedTime,
        },
        errorMessage: errorMessage,
      });
    }
  }

  handleCheckOutChange = (params) => {
    const {value} = params;
    const selectedTime = moment(value).format('HH:mm');
    const {formData, errorMessage} = this.state;
    const {check_in} = formData;
    if (selectedTime <= check_in) {
      this.setState({
        formData: {
          ...this.state.formData,
          check_out: selectedTime,
        },
        errorMessage: {
          ...errorMessage,
          check_out: 'Check out time must be greater then check in time',
        },
      });
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          check_out: selectedTime,
        },
        errorMessage: {
          ...errorMessage,
          check_out: null,
        },
      });
    }
  }

  handleResetForm = (closeModal) => {
    this.setState({
      errorMessage: null,
      formData: {
        date: null,
        todo: null,
        check_in: null,
        check_out: null,
        status: '',
        reason: null,
      },
    });
    if (closeModal) this.setState({showDetail: false, showReject: false, showApprove: false});
  };

  renderTableTitle = () => {
    const {classes} = this.props;
    return (
      <div className={classes.MUIDataTableToolbar}>
        <div className="d-flex">
          <Typography className={classes.tableTitle}>Overtime List</Typography>
          <ThemeProvider theme={this.tooltipTheme()}>
            <Tooltip title="Click Filter to see all data from overtime list">
              <IconButton className={classes.tableTooltip}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </ThemeProvider>
          {/* <span className="">Remaining Quota: {remaining_quota}</span>   */}
        </div>
      </div>
    );
  };


  handleChangeStatus = (data, status) => {
    this.setState({
      selected: data[7],
      formData: {
        ...this.state.formData,
        status: status,
        reason: '',
      },
      detailData: {
        ...this.state.detailData,
        date: data[3],
        todo: data[11],
        check_in_id: data[9],
        check_in_time: data[5],
        check_out_id: data[10],
        check_out_time: data[6],
        rejected_reason: data[12],
      },
    }, () => {
      if (status === 'approved') {
        Swal.fire({
          title: 'Confirmation',
          text: 'Are you sure, Approve this Overtime ? ',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#00AFF0',
          cancelButtonColor: '#E5AF5A',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.value) {
            this.changeOvertimeStatus();
          }
        });
      } else {
        this.setState({showReject: true});
      }
    });
  }

  handleChangeStatusApprove = (data, status) => {
    this.setState({
      selected: data[7],
      formData: {
        ...this.state.formData,
        status: 'approved',
        reason: '',
      },
      detailData: {
        ...this.state.detailData,
        date: data[3],
        todo: data[11],
        check_in_id: data[9],
        check_in_time: data[5],
        check_out_id: data[10],
        check_out_time: data[6],
        rejected_reason: data[12],
      },
    }, () => {
      this.setState({showApprove: true});
    });
  }

  changeOvertimeStatus = () => {
    const {formData, detailData, showReject, showDetail, selectedStatus} = this.state;
    const {date, todo, check_in, check_out, status, reason} = formData;
    const {check_in_id, check_out_id} = detailData;
    const errors = {};
    const params = {};
    if (showReject && !reason) {
      if (!reason) errors.reason = 'Reason is required';
      this.setState({errorMessage: errors});
    } else if (
      showDetail &&
      (
        !date ||
        !todo ||
        (check_in === 'Invalid date' || !check_in) ||
        moment(check_in, 'HH:mm') > moment(check_out, 'HH:mm') ||
        (check_out === 'Invalid date' || !check_out) ||
        moment(check_out, 'HH:mm') < moment(check_in, 'HH:mm') ||
        (!status || status === 'waiting') ||
        (!reason)
      )
    ) {
      if (!date) errors.date = 'Date is required';
      if (!todo) errors.todo = 'Todo is required';
      if (check_in === 'Invalid date' || !check_in) errors.check_in = 'Check in time is required';
      if (moment(check_in, 'HH:mm') > moment(check_out, 'HH:mm')) errors.check_in = 'Check in time must be lower then check out time';
      if (check_out === 'Invalid date' || !check_out) errors.check_out = 'Check out time is required';
      if (moment(check_out, 'HH:mm') < moment(check_in, 'HH:mm')) errors.check_out = 'Check out time must be greater then check in time';
      if (!status || status === 'waiting') errors.status = 'Please select status';
      if (!reason) errors.reason = 'Reason is required';
      this.setState({errorMessage: errors});
    } else {
      params.check_in_id = check_in_id;
      params.check_out_id = check_out_id ? check_out_id : null;
      params.check_in_time = showDetail ? check_in : detailData.check_in_time;
      params.check_out_time = showDetail ? check_out : detailData.check_out_time;
      params.date = showDetail ? date ? moment(date).format('YYYY-MM-DD') : null : detailData.date ? moment(detailData.date).format('YYYY-MM-DD') : null;
      params.todo = showDetail ? todo : detailData.todo;
      if (selectedStatus !==status) {
        params.status = status;
      }
      params.rejected_reason = reason ? reason : '';
      this.setState({errorMessage: {}});
      this.props.putChangeOvertimeStatus(params);
    }
  };

  changeOvertimeStatusApprove = () => {
    const {formData, detailData, showApprove, showDetail, selectedStatus} = this.state;
    const {date, todo, check_in, check_out, status, reason} = formData;
    const {check_in_id, check_out_id} = detailData;
    const errors = {};
    const params = {};
    if (reason.length === 0) {
      errors.reason = 'Reason is required';
      this.setState({errorMessage: errors});
    } else {
      params.check_in_id = check_in_id;
      params.check_out_id = check_out_id ? check_out_id : null;
      params.check_in_time = showDetail ? check_in : detailData.check_in_time;
      params.check_out_time = showDetail ? check_out : detailData.check_out_time;
      params.date = showDetail ? date ? moment(date).format('YYYY-MM-DD') : null : detailData.date ? moment(detailData.date).format('YYYY-MM-DD') : null;
      params.todo = showDetail ? todo : detailData.todo;
      if (selectedStatus !==status) {
        params.status = 'approved';
      }
      params.rejected_reason = reason ? reason : '';
      this.setState({errorMessage: {}});
      this.props.putChangeOvertimeStatus(params);
    }
  };

  renderFilter = () => {
    const {classes, message, exportLoading, exportData, isSuccessPost, overtimeData} = this.props;
    const {query, startDate, endDate, status, searchBy} = this.state;
    const renderMethodOptions = () => {
      const options = [
        {id: 'all', name: 'All'},
        // {id: 'waiting', name: 'Waiting'},
        {id: 'approved', name: 'Approved'},
        {id: 'rejected', name: 'Rejected'},
      ];
      return options;
    };

    const renderSearchByOptions = () => {
      const options = [
        {id: 'submission_date', name: 'Overtime Date'},
        {id: 'confirmed_date', name: 'Confirmed Date'},
      ];
      return options;
    };

    return (
      <Paper variant='outlined' className={classes.paper}>
        {this.state.filterCheckbox ? (
          <>
            <MInput
              autoFocus
              className={`${classes.filter} ${classes.formControlSearch}`}
              fullWidth={false}
              variant="standard"
              name="query"
              label="Search"
              placeholder="Search keyword"
              value={query}
              onChange={this.handleSearch}
              InputProps={{
                endAdornment: (
                  <IconButton position="end">
                    <Search className={classes.searchIcon} />
                  </IconButton>
                ),
              }}
            />
            <ThemeProvider theme={this.checkboxTheme()}>
              <FormControlLabel
                style={{display: 'inline-flex', verticalAlign: 'bottom'}}
                control={
                  <Checkbox
                    color='primary'
                    checked={this.state.filterCheckbox}
                    onChange={(e) => this.handleFilterCheckbox(e)}
                  />
                }
                label="Filter"
              />
            </ThemeProvider>
            <MSelect
              shrink={true}
              fullWidth={false}
              classNameFC={`${classes.formControlSearchBy} ${classes.filter}`}
              variant="standard"
              name="searchBy"
              label="Search By"
              keyPair={['id', 'name']}
              options={renderSearchByOptions()}
              value={searchBy}
              onChange={this.handleFilterChange}
            />
            <MSelect
              shrink={true}
              fullWidth={false}
              classNameFC={`${classes.formControlStatus} ${classes.filter}`}
              variant="standard"
              name="status"
              label="Status"
              keyPair={['id', 'name']}
              options={renderMethodOptions()}
              value={status}
              onChange={this.handleFilterChange}
            />
            <MKeyBoardDatepicker
              className={classes.filterDatePicker}
              name="startDate"
              label="Start Date"
              value={startDate}
              onDateChange={this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              maxDate={endDate ? new Date(endDate) : undefined}
              error={
                this.state.startDate === null && this.state.endDate !== null ? 'Start Date is required' :
                  startDate === null && this.state.errorFilterDate ? 'Start Date is required' :
                    undefined
              }
            />
            <MKeyBoardDatepicker
              className={classes.filterDatePicker}
              name="endDate"
              label="End Date"
              value={endDate}
              onDateChange={this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              minDate={new Date(startDate)}
              error={
                this.state.startDate !== null && this.state.endDate === null ? 'End Date is required' :
                  endDate === null && this.state.errorFilterDate ? 'End Date is required' :
                    undefined
              }
            />
            <div className={classes.btnFilterContainer}>
              <MButton
                className={classes.filterBtnApply}
                label="Apply"
                color="primary"
                onClick={this.handleButtonFilter}
              />
            </div>
            <div className={classes.btnFilterContainer}>
              <MButton
                className={classes.filterBtn}
                label="Reset"
                icon={<Refresh />}
                onClick={this.handleReset}
              />
            </div>
            <div className={classes.btnFilterContainer}>
              <MButton
                className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                label="Export"
                icon={<SaveAlt />}
                onClick={this.handleExport}
                loading={exportLoading}
                disabled={overtimeData?.length < 1 ? true : false}
              />
            </div>
            <br />
            {/* <Typography style={{margin: '0px 4px 0', justifyContent: 'end', display: (exportData) ? 'flex' : 'none'}}>
              or click &nbsp;
              <Link href={exportData} target="_blank" >this link</Link>
                &nbsp;
                if file corrupt
            </Typography> */}
          </>
        ) : (
          <Grid container justifyContent='space-between'>
            <Grid item>
              <MInput
                autoFocus
                className={`${classes.filter} ${classes.searchInput}`}
                fullWidth={false}
                variant="standard"
                name="query"
                label="Search"
                placeholder="Search keyword"
                value={query}
                onChange={this.handleSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton position="end">
                      <Search className={classes.searchIcon} />
                    </IconButton>
                  ),
                }}
              />
              <ThemeProvider theme={this.checkboxTheme()}>
                <FormControlLabel
                  style={!this.state.filterCheckbox ?{marginTop: 22} : {marginRight: 24, marginTop: 22}}
                  control={
                    <Checkbox
                      color='primary'
                      checked={this.state.filterCheckbox}
                      onChange={(e) => this.handleFilterCheckbox(e)}
                    />
                  }
                  label="Filter"
                />
              </ThemeProvider>
            </Grid>
            <Grid item>
              <div className={classes.btnFilterContainer}>
                <MButton
                  className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                  style={{marginTop: 22}}
                  label="Export"
                  icon={<SaveAlt/>}
                  onClick={this.handleExport}
                  loading={exportLoading}
                  disabled={overtimeData?.length < 1 ? true : false}
                />
                <br />
              </div>
              {/* <Typography style={{margin: '0px 4px 0', justifyContent: 'end', display: (exportData) ? 'flex' : 'none'}}>
              or click &nbsp;
                <Link href={exportData} target="_blank" >this link</Link>
                &nbsp;
                if file corrupt
              </Typography> */}
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  };

  toggleTimelineAccordion = () => {
    this.setState({
      timelineAccordion: !this.state.timelineAccordion,
    });
  }

  renderModalDetail = () => {
    const {classes, isLoadingPost} = this.props;
    const {showDetail, detailData, errorMessage, formData, timelineAccordion} = this.state;
    const name = detailData && detailData.name ? detailData.name : '-';
    const date =
      detailData && detailData.date ?
        convDate(detailData.date, 'D MMMM YYYY') :
        '-';
    const checkIn =
      detailData && detailData.check_in ? detailData.check_in : '-';
    const checkInImg =
      detailData && detailData.check_in_img ? (
        detailData.check_in_img
      ) : (
        <Avatar alt="user avatar" />
      );
    const checkOut =
      detailData && detailData.check_out ? detailData.check_out : '-';
    const checkOutImg =
      detailData && detailData.check_out_img ? (
        detailData.check_out_img
      ) : (
        <Avatar alt="user avatar" />
      );
    const checkInAddress =
      detailData && detailData.check_in_address ?
        detailData.check_in_address :
        '-';
    const checkOutAddress =
      detailData && detailData.check_out_address ?
        detailData.check_out_address :
        '-';
    const todo = detailData && detailData.todo ? detailData.todo : '-';
    const day = detailData && detailData.day ? detailData.day : '-';

    const method = detailData && detailData.method ? detailData.method : null;
    const columns = ['Check In', 'Address', 'Todo', 'Photo'];

    const approvedBy =
      detailData && detailData.approved_by ? detailData.approved_by : '-';
    const rejected_reason =
      detailData && detailData.rejected_reason ?
        detailData.rejected_reason :
        '';

    const pic_name = detailData && detailData.approve ? detailData.approve : '-';
    const status = detailData && detailData.status ? detailData.status : null;
    const id =
      detailData && detailData.check_out_id ? detailData.check_out_id : null;
    const options = [
      {id: 'rejected', name: 'Rejected'},
      {id: 'approved', name: 'Approved'},
    ];

    const reversed = formData?.approval_history ? formData.approval_history : [];

    return (
      <Dialog
        open={showDetail}
        onClose={() => this.toggleModal('close')}
        maxWidth="md"
        fullWidth={true}
        scroll="paper"
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Overtime Detail"
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.dialogContent}>
          <div style={{marginBottom: 24}}>
            <ThemeProvider theme={this.accordionTheme()}>
              <Accordion expanded={timelineAccordion} onChange={this.toggleTimelineAccordion} variant='outlined'>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.accordionTitle}>
                  Status
                  </Typography>
                  <Typography
                    className={status === 'request' ? classes.statusRequest :
                      status === 'on_going' ? classes.statusOngoing :
                        status === 'rejected' ? classes.statusRejected :
                          status === 'approved' ? classes.statusCompleted : null}
                  >
                    {status === 'request' ? 'Request' :
                      status === 'on_going' ? 'On Going' :
                        status === 'rejected' ? 'Rejected' :
                          status === 'approved' ? 'Approved' : null}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider style={{marginBottom: 8}} />
                  <ThemeProvider theme={this.timelineTheme()}>
                    <Timeline
                      sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                    >
                      {reversed?.map((data, index) => {
                        return (
                          <TimelineItem key={index.toString()}>
                            <TimelineSeparator>
                              <TimelineDot color={ data.status === 'request' ? 'primary' :
                                 data.status === 'on_going' ? 'secondary' :
                                 data.status === 'rejected' ? 'warning' :
                                 data.status === 'approved' ? 'success' : 'grey'} />
                              {index === reversed?.length - 1 ? (null) : (<TimelineConnector />)}
                            </TimelineSeparator>
                            <TimelineContent>
                              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div>
                                  <Typography
                                    className={data.status === 'request' ? classes.statusRequest :
                                      data.status === 'on_going' ? classes.statusOngoing :
                                        data.status === 'rejected' ? classes.statusRejected :
                                          data.status === 'approved' ? classes.statusCompleted : null}
                                  >
                                    {data.status === 'request' ? 'Request' :
                                      data.status === 'on_going' ? 'On Going' :
                                        data.status === 'rejected' ? 'Rejected' :
                                          data.status === 'approved' ? 'Approved' : null}
                                  </Typography>
                                  {data.status === 'approved' && (
                                    <Typography className={classes.timelineDate}>
                                      Approved by: {data.name}
                                    </Typography>
                                  )}
                                  <Typography
                                    className={classes.timelineDate}
                                  >
                                    {data.status === 'rejected' ? detailData.rejected_reason : null}
                                  </Typography>
                                </div>
                                <Typography className={classes.timelineDate}>{convDate(detailData.date, 'DD-MM-YYYY')}</Typography>
                              </div>
                            </TimelineContent>
                          </TimelineItem>
                        );
                      })}
                    </Timeline>
                  </ThemeProvider>
                </AccordionDetails>
              </Accordion>
            </ThemeProvider>
          </div>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={2}>
              <Typography>Name </Typography>
            </Grid>
            <Grid item>
              <Typography> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography>{name}</Typography>
            </Grid>
          </Grid>
          <div>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs={2}>
                <Typography>Address Checkin</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{checkInAddress}</Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2} mb={5}>
              <Grid item xs={2}>
                <Typography>Address Checkout</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{checkOutAddress}</Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2} mb={5}>
              <Grid item xs={2}>
                <Typography>PIC</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{pic_name}</Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2} mb={5}>
              <Grid item xs={2}>
                <Typography>Approved by</Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{approvedBy}</Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs={2}>
                <Typography>Day </Typography>
              </Grid>
              <Grid item>
                <Typography> : </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography>{getDay(formData.date)}</Typography>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <MDatepicker
                className={classes.formControl}
                name="date"
                label="Date"
                value={convDate(formData.date)}
                onDateChange={this.handleDateChange}
                error={
                  errorMessage && errorMessage.date ? errorMessage.date : undefined
                }
                placeholder="dd-mm-yyyy"
                maxDate={new Date()}
                inputVariant="outlined"
                disableFuture
                views={['year', 'month', 'date']}
                fullWidth
              />
            </Grid>
            <Grid container wrap="nowrap" spacing={2}>
              <MInput
                classNameFC={classes.formControl}
                multiline
                rows={2}
                rowsMax={3}
                name="todo"
                label="Todo *"
                placeholder="Fill in todo"
                defaultValue={formData.todo}
                onBlur={this.handleInputChange}
                error={
                  errorMessage && errorMessage.todo ?
                    errorMessage.todo :
                    undefined
                }
              />
            </Grid>
          </div>
          <br />
          <Divider />
          <Grid container spacing={3} align="center">
            <Grid item xs={6}>
              <Typography className={classes.modalItemSpacing}>
                Check In
              </Typography>
              <Link href={checkInImg} target="_blank" rel="noopener noreferrer">
                <CardMedia
                  className={[classes.modalAvatar, classes.imgSize]}
                  alt="check in picture"
                  image={checkInImg}
                  title="Check In"
                  component="image"
                />
              </Link>
              <br />
              <Grid container wrap="nowrap" spacing={2} align="center">
                <Grid item xs={12}>
                  <MKeyboardTimePicker
                    // inputVariant="outlined"
                    className={classes.formControl}
                    label={'Check In'}
                    name={'check_in'}
                    value={moment(formData.check_in, 'HH:mm')}
                    onTimeChange={(event) => this.handleCheckInChange(event)}
                    ampm={false}
                    error={
                      errorMessage && errorMessage.check_in ?
                        errorMessage.check_in :
                        undefined
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.modalItemSpacing}>
                Check Out
              </Typography>
              <Link
                href={checkOutImg}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardMedia
                  className={[classes.modalAvatar, classes.imgSize]}
                  alt="check out picture"
                  image={checkOutImg}
                  title="Check Out"
                  component="image"
                />
              </Link>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MKeyboardTimePicker
                    className={classes.formControl}
                    label={'Check Out'}
                    name={'check_out'}
                    value={moment(formData.check_out, 'HH:mm')}
                    onTimeChange={(event) => this.handleCheckOutChange(event)}
                    ampm={false}
                    error={
                      errorMessage && errorMessage.check_out ?
                        errorMessage.check_out :
                        undefined
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid>
            <MSelect
              fullWidth
              classNameFC={classes.formControl}
              name="status"
              label="Status *"
              placeholder="Confirm status"
              keyPair={['id', 'name']}
              options={options}
              defaultValue={status}
              onChange={this.handleInputChange}
              error={
                errorMessage && errorMessage.status ?
                  errorMessage.status :
                  undefined
              }
            />
          </Grid>
          <Grid>
            <MInput
              classNameFC={classes.formControl}
              multiline
              rows={2}
              rowsMax={3}
              name="reason"
              label="Notes *"
              placeholder="Fill in notes"
              defaultValue={rejected_reason}
              onBlur={this.handleInputChange}
              error={
                errorMessage && errorMessage.reason ?
                  errorMessage.reason :
                  undefined
              }
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <MButton className={classes.cancelBtn}
            label="Cancel"
            onClick={() => this.toggleModal('close')}
          />
          <MButton
            className={classes.defaultBtn}
            label="Update"
            icon={<Send />}
            onClick={() => this.changeOvertimeStatus()}
            loading={isLoadingPost}
          />
        </DialogActions>
      </Dialog>
    );
  };

  renderModalRejected = () => {
    const {classes, isLoadingPost} = this.props;
    const {showReject, detailData, errorMessage} = this.state;
    const rejected_reason =
      detailData && detailData.rejected_reason ?
        detailData.rejected_reason :
        '';
    const id = detailData && detailData.id ? detailData.id : null;
    return (
      <Dialog
        open={showReject}
        onClose={() => this.toggleModal('close')}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Overtime Reject"
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.dialogContent}>
          <MInput
            classNameFC={classes.formControl}
            multiline
            rows={2}
            rowsMax={3}
            name="reason"
            label="Notes *"
            placeholder="Fill in notes"
            defaultValue={rejected_reason}
            onBlur={this.handleInputChange}
            error={
              errorMessage && errorMessage.reason ?
                errorMessage.reason :
                undefined
            }
          />
        </DialogContent>
        <DialogActions>
          <MButton label="Cancel" className={classes.cancelBtn} onClick={() => this.toggleModal('close')} />
          <MButton
            className={classes.defaultBtnReject}
            label="Reject"
            icon={<Send />}
            onClick={() => this.changeOvertimeStatus()}
            loading={isLoadingPost}
          />
        </DialogActions>
      </Dialog>
    );
  };

  renderModalApproved = () => {
    const {classes, isLoadingPost} = this.props;
    const {showApprove, detailData, errorMessage} = this.state;
    const rejected_reason =
      detailData && detailData.rejected_reason ?
        detailData.rejected_reason :
        '';
    const id = detailData && detailData.id ? detailData.id : null;
    return (
      <Dialog
        open={showApprove}
        onClose={() => this.toggleModal('close')}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Overtime Approve"
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.dialogContent}>
          <MInput
            classNameFC={classes.formControl}
            multiline
            rows={2}
            rowsMax={3}
            name="reason"
            label="Notes *"
            placeholder="Fill in notes"
            defaultValue={rejected_reason}
            onBlur={this.handleInputChange}
            error={
              errorMessage && errorMessage.reason ?
                errorMessage.reason :
                undefined
            }
          />
        </DialogContent>
        <DialogActions>
          <MButton label="Cancel" className={classes.cancelBtn} onClick={() => this.toggleModal('close')} />
          <MButton
            className={classes.defaultBtnReject}
            label="Approve"
            icon={<Send />}
            onClick={() => this.changeOvertimeStatusApprove()}
            loading={isLoadingPost}
          />
        </DialogActions>
      </Dialog>
    );
  };


  renderActions = (params) => {
    return (
      <div style={{whiteSpace: 'nowrap'}}>
        {
        params[7] && params[7] === 'waiting' && params[9] && params[9] !==null && params[6] && params[6] !==null?
        (<>
          <div style={{display: 'inline', marginRight: '5px'}}>
            <Tooltip title="Approved">
              <Button
                variant="contained"
                // color="primary"
                style={{
                  'backgroundColor': '#008000',
                  'boxShadow': 'none',
                  'color': '#fff',
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#007000',
                  },
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
                size="small"
                onClick={() => this.handleChangeStatusApprove(params, 'approved')}
                startIcon={<CheckCircle />}
              >
              </Button>
            </Tooltip>
          </div>
          <div style={{display: 'inline', marginRight: '5px'}}>
            <Tooltip title="Rejected">
              <Button
                variant="contained"
                // color="primary"
                size="small"
                style={{
                  'backgroundColor': '#E5AF5A',
                  'boxShadow': 'none',
                  'color': '#fff',
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#D09F53',
                  },
                  'minWidth': '10px',
                  'paddingRight': '0px',
                }}
                onClick={() => this.handleChangeStatus(params, 'rejected')}
                startIcon={<Cancel />}>
              </Button>
            </Tooltip>
          </div>
        </>
      ) :
      null}

      </div>
    );
  };

  setTIme = (date) => {
    let time;
    if (date) {
      time = moment(date).format('HH:mm');
    } else {
      time = null;
    }
    return time;
  }

  render() {
    const {classes, overtimeData, overtimeMeta, isLoading} = this.props;
    const {resetDatatable, resetPage} = this.state;
    const columns = [
      {name: 'no', label: 'No', display: true},
      {
        name: 'name',
        label: 'Name',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'method',
        label: 'Type',
        display: true,
        customBodyRender: (value) => {
          if (value === 'business') value = 'Business Trip';
          if (value === 'office') value = 'Office Working';
          if (value === 'remote') value = 'Remote Working';
          return <div style={{textTransform: 'capitalize'}}>{value}</div>;
        },
      },
      {
        name: 'date',
        label: 'Overtime Date',
        display: true,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value ? convDate(value, 'DD-MM-YYYY') : '-'}</div>;
        },
      },
      {
        name: 'day',
        label: 'Day',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'check_in',
        label: 'Check In',
        display: true,
        customBodyRender: (value) => {
          return value ? (
            <Button
              variant="outlined"
              size="small"
              startIcon={<CheckCircleOutline className={classes.checkInTrue} />}
              onClick={this.handleModal}
            >
              {value}
            </Button>
          ) : (
            '-'
          );
        },
      },
      {
        name: 'check_out',
        label: 'Check Out',
        display: true,
        customBodyRender: (value) => {
          return value ? (
            <Button
              variant="outlined"
              size="small"
              startIcon={<CheckCircleOutline className={classes.checkInTrue} />}
              onClick={this.handleModal}
            >
              {value}
            </Button>
          ) : (
            '-'
          );
        },
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) =>
          value ? value.charAt(0).toUpperCase() + value.slice(1) : '-',
      },
      {
        name: 'working_time',
        label: 'Working Time',
        display: true,
        customBodyRender: (value) =>
          value ? value.charAt(0).toUpperCase() + value.slice(1) : '-',
      },
      {
        name: 'check_in_id',
        label: 'check_in_id',
        display: false,
      },
      {
        name: 'check_out_id',
        label: 'check_out_id',
        display: false,
      },
      {
        name: 'todo',
        label: 'todo',
        display: false,
      },
      {
        name: 'approved_by',
        label: 'Approved By',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
    ];

    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <Datatable
          creatable={false}
          title={this.renderTableTitle()}
          loading={isLoading}
          dataSource={overtimeData}
          total={overtimeMeta.total}
          page={overtimeMeta.page}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          handleDetail={(params) => this.toggleModal('detail', params)}
          customActions={(params) => this.renderActions(params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
        />
        {this.renderModalDetail()}
        {this.renderModalRejected()}
        {this.renderModalApproved()}
      </div>
    );
  }
}

const mapStateToProps = ({overtimeHistory, userManagement}) => {
  return {
    isLoading: overtimeHistory.isLoading,
    isSuccess: overtimeHistory.isSuccess,
    isError: overtimeHistory.isError,
    message: overtimeHistory.message,
    overtimeData: overtimeHistory.overtimeData,
    overtimeMeta: overtimeHistory.overtimeMeta,
    detailLoading: overtimeHistory.detailLoading,
    detailData: overtimeHistory.detailData,
    trigger: overtimeHistory.trigger,
    attendanceTypes: userManagement.attendanceTypes,
    isLoadingPost: overtimeHistory.isLoadingPost,
    isSuccessPost: overtimeHistory.isSuccessPost,
    isErrorPost: overtimeHistory.isErrorPost,
    messagePost: overtimeHistory.messagePost,
    status: overtimeHistory.status,
    exportLoading: overtimeHistory.exportLoading,
    exportData: overtimeHistory.exportData.file,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOvertimeHistory: (params) => dispatch(getOvertimeHistory(params)),
  exportExcel: (params) => dispatch(exportExcel(params)),
  putChangeOvertimeStatus: (params) =>
    dispatch(putChangeOvertimeStatus(params)),
  checkStatus: () => dispatch(checkStatus()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(OvertimeHistory));
