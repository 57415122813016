/* eslint-disable no-case-declarations */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
import {initialState} from '../auth/auth.types';
import {LOGIN_STATE} from '../login/login.types';
import {ORGANIZATION_STATE} from '../organization/organization.types';
import {USER_STATE} from '../userManagement/userManagement.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_STATE.LOGIN_SUCCESS:
      return {
        ...state,
        data: action?.payload?.data,
      };
    case LOGIN_STATE.LOGIN_SSO_SUCCESS:
      return {
        ...state,
        data: action?.payload?.data,
      };
    case ORGANIZATION_STATE.UPDATE_MY_PROFILE: // update reducer auth.profile in real time without RELOG
      let newLocation = action.payload.location ? action.payload.location.filter((value) => {
        return value !==null;
      }) : null;
      return {
        ...state,
        data: {
          ...state.data,
          organization: {
            ...state.data.organization,
            ...(action.payload.name && {name: action.payload.name}),
            ...(action.payload.address && {address: action.payload.address}),
            ...(action.payload.longitude && {longitude: action.payload.longitude}),
            ...(action.payload.latitude && {latitude: action.payload.latitude}),
            ...(action.payload.radius && {radius: action.payload.radius}),
            ...(action.payload.logo && {logo: action.payload.logo}),
            ...(newLocation && {location: newLocation}),
            ...(action.payload.pic_name && {pic_name: action.payload.pic_name}),
            ...(action.payload.pic_number && {pic_number: action.payload.pic_number}),
            ...(action.payload.pic_email && {pic_email: action.payload.pic_email}),
          },
        },
      };
    case USER_STATE.CHECK_STATUS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          menus: {
            ...state.data.menus,
            ...(action.payload && action.payload),
          },
        },
      };
    case LOGIN_STATE.UPDATE_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          authorization: action.payload.data.authorization,
          accessToken: action?.payload?.data?.accessToken,
        },
      };
    case LOGIN_STATE.LOGOUT:
      return {
        ...state,
        data: {
          authorization: '',
          profile: [],
          organization: [],
          menus: [],
        },
      };
    default:
      return state;
  }
};
