/* eslint-disable max-len */
import {
  Box,
  Grid,
  IconButton,
  Typography,
  withStyles,
  Divider,
  Tooltip,
  FormHelperText,
} from '@material-ui/core';

import {ArrowBack, HelpOutline, Search} from '@material-ui/icons';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MInput, MSelect, MButton, MDatepicker} from '../../../components/form';
import LocationPicker from '../components/LocationPicker';
import {useHistory, useLocation} from 'react-router-dom';
import PaymentPackage from '../components/PaymentPackage';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {createEvent, getEventDetail, updateEvent} from '../../../redux/eventManagement/eventManagement.actions';
import {OpenStreetMapProvider} from 'leaflet-geosearch';
import {debounce as _debounce, capitalize as _capitalize} from 'lodash';
import {formatCurrency2, getImageUrl, isValidURL} from '../../../helpers';
import * as yup from 'yup';
import {useForm, useFieldArray} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {MyEditor} from '../../../components';
import styles from './styles';
import './styles.css';

const EVENT_MAX_PRICE = process?.env?.REACT_APP_EVENT_MAX_PRICE || 9999999;
const EVENT_MAX_PARTICIPANTS = process?.env?.REACT_APP_EVENT_MAX_PARTICIPANTS || 2000;
const SUPPORTED_FILE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

const currencyOptions = [
  {id: 'idr', name: 'IDR'},
  {id: 'usd', name: 'USD'},
];

const eventTypeOptions = [
  {id: 'public', name: 'Public'},
  {id: 'private', name: 'Private'},
];

const packageOptions = [
  {id: 'free', name: 'Free'},
  {id: 'paid', name: 'Paid'},
];

const initialForm = {
  title: null,
  date_start: null,
  date_end: null,
  expired_registration: null,
  description: null,
  events_img: null,
  address: null,
  latitude: null,
  longitude: null,
  currency: currencyOptions[0]?.id, // idr or usd
  link: 'http://www.example.com',
  type: null, // public or private
  payment_type: null,
  maximum_participants: null,
  packages: null,
};

const initialMarker = {
  lat: 51.505,
  lng: -0.09,
  label: null,
};

const descriptionValidator = (value) => {
  const result = new DOMParser().parseFromString(value || '', 'text/html')?.body?.textContent?.trim()?.length;
  if (!result) return false;
  return true;
};

const fileSizeValidator = (value) => !value || isValidURL(value) ? true : value.size <= 5000000;
const fileFormatValidator = (value) => !value || isValidURL(value) ? true : SUPPORTED_FILE_FORMATS.includes(value.type);

const packageYupSchema = yup.array(
  yup.object({
    name: yup.string().required(),
    price: yup.number().required()
      .min(1, `Package Price must be at least ${formatCurrency2(1)}`)
      .max(EVENT_MAX_PRICE, `Package Price must be less than ${formatCurrency2(EVENT_MAX_PRICE)}`),
    description: yup.string().required(),
    maximum_participants: yup.number().positive().required(),
    index: yup.number().positive().required(),
  }),
).when('payment_type', {
  is: packageOptions[1].id,
  then: (schema) => schema.required(),
  otherwise: (schema) => schema.optional(),
});

const eventSchema = {
  title: yup.string().required(),
  description: yup.string().test('not-empty', 'Description is a required field', descriptionValidator).required(),
  address: yup.string().required(),
  latitude: yup.number().required(),
  longitude: yup.number().required(),
  maximum_participants: yup.number().positive().integer().required().max(EVENT_MAX_PARTICIPANTS),
  type: yup.string().oneOf(eventTypeOptions.map((item) => item.id)).required(),
  payment_type: yup.string().oneOf(packageOptions.map((item) => item.id)).required(),
  date_start: yup.date().min(moment().startOf('day')).required(),
  date_end: yup.date().min(yup.ref('date_start')).required(),
  expired_registration: yup.date().min(moment().startOf('day')).max(yup.ref('date_start')).required(),
  packages: packageYupSchema,
};

const schemaCreate = yup
  .object({
    ...eventSchema,
    events_img: yup.mixed().required()
      .test('fileSize', 'File size is too large (maximum 5 MB)', fileSizeValidator)
      .test('fileType', 'File type is not supported', fileFormatValidator),
  })
  .required();

const schemaUpdate = yup
  .object({
    ...eventSchema,
    date_start: yup.date().required(),
    date_end: yup.date().required(),
    expired_registration: yup.date().required(),
    packages: packageYupSchema,
    events_img: yup.mixed().optional().nullable()
      .test('fileSize', 'File size is too large (maximum 5 MB)', fileSizeValidator)
      .test('fileType', 'File type is not supported', fileFormatValidator),
  })
  .required();

function CreateEvent({classes}) {
  const provider = new OpenStreetMapProvider();
  const dispatch = useDispatch();
  const history = useHistory();
  const {state} = useLocation();
  const [formData, setFormData] = useState({...initialForm});
  const [marker, setMarker] = useState({...initialMarker});
  const [search, setSearch] = useState('');
  const [imgPreview, setImgPreview] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const locationState = useMemo(() => state ? state : {}, [state]);

  const {
    isLoading,
    message: errors,
    eventDetail,
  } = useSelector((state) => state.eventManagement);

  const {
    clearErrors,
    control,
    formState: {errors: errorForms},
    handleSubmit,
    register,
    setValue,
    getValues,
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(locationState?.isEdit ? schemaUpdate : schemaCreate),
    defaultValues: {
      ...initialForm,
    },
  });

  const {append, fields, remove} = useFieldArray({
    name: 'packages',
    control,
  });

  const onSubmit = (data, status) => {
    if (!data?.events_img) {
      setError('events_img', {message: 'Photo is required', type: 'required'});
      return;
    }

    const dataForm = new FormData();

    Object.keys(data).forEach((key) => {
      if (key === 'latitude') {
        dataForm.append('location[lat]', data[key]);
      } else if (key === 'longitude') {
        dataForm.append('location[long]', data[key]);
      } else if (key === 'address') {
        dataForm.append('location[address]', data[key]);
      } else {
        dataForm.append(key, data[key]);
      }
    });

    dataForm.delete('packages');

    if (data?.payment_type === 'paid' && data?.packages?.length) {
      data.packages.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          dataForm.append(`package[${index}][${key}]`, data.packages[index][key]);
        });
      });
    }

    if (locationState?.isEdit && isValidURL(data?.events_img)) {
      dataForm.delete('events_img');
    }

    dataForm.append('status', status);

    if (locationState?.isEdit) {
      dispatch(updateEvent(eventDetail?.id, dataForm, history));
    } else {
      dispatch(createEvent(dataForm, history));
    }
  };

  const handleAddPackage = () => {
    const _packages = formData?.packages || [];
    const nextIndex = Number(_packages[_packages.length - 1]?.index) + 1 || 1;

    const newPackage = {
      name: null,
      price: null,
      description: null,
      maximum_participants: null,
      index: nextIndex,
    };

    _packages.push(newPackage);

    setFormData((prevState) => ({
      ...prevState,
      payment_type: 'paid',
      packages: [..._packages],
    }));

    append(newPackage);
  };

  const handleDeletePackage = (index) => {
    const updatedPackages = formData?.packages?.filter((item, _index) => _index !== index);
    const updatedCounter = updatedPackages.reduce((prev, current) => prev + Number(current?.maximum_participants), 0);

    setFormData((prevState) => ({
      ...prevState,
      maximum_participants: updatedCounter,
      packages: [...updatedPackages],
    }));

    remove(index);
  };

  const handleInputChange = (event) => {
    const {name, value} = event?.target || {};

    setValue(name, value);
    if (value) clearErrors(name);

    if (name === 'payment_type') {
      if (value === packageOptions[0].id) {
        remove();

        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
          packages: [],
        }));
      } else if (!formData?.packages?.length) {
        handleAddPackage();
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
          [name]: value,
      }));
    }
  };

  const handleDateChange = (payload) => {
    const {name, value} = payload || {};
    const result = moment(value).startOf('day');
    const objResult = {[name]: result};

    if (name === 'date_start') {
      if (formData?.date_end) {
        const endDate = moment(formData?.date_end).startOf('day');

        if (result.isAfter(endDate)) {
          objResult.date_end = result;
        }
      }

      if (formData?.expired_registration) {
        const expiredDate = moment(formData?.expired_registration).startOf('day');

        if (expiredDate.isAfter(result)) {
          objResult.expired_registration = result;
        }
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      ...objResult,
    }));

    Object.keys(objResult).forEach((item) => {
      setValue(item, objResult[item]);
    });

    if (value) clearErrors(name);
  };

  const getBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImgPreview(reader.result);
    };
    reader.onerror = (error) => {
      console.log('Upload file error: ', error);
    };
  };

  const handleInputFile = (event) => {
    const file = event?.target?.files[0];
    getBase64(file);

    setFormData((prevState) => ({
      ...prevState,
      events_img: file,
    }));

    setValue('events_img', file);
    clearErrors('events_img');
  };

  const redirectToEventDashboard = () => {
    if (locationState?.isEdit) {
      history.goBack();
    } else {
      history.push('/event-management');
    }
  };

  const handlePackageChange = (index, event) => {
    const {name, value} = event?.target || {};
    const _packages = formData?.packages || [];
    const additionals = {};
    const _formName = name.replace(`packages[${index}].`, '');
    _packages[index][_formName] = value;

    if (name.search('maximum_participants' > -1)) {
      const totalParticipants = _packages.reduce((acc, current) => acc + (Number(current?.maximum_participants) || 0), 0);
      additionals.maximum_participants = totalParticipants;
      setValue('maximum_participants', totalParticipants);

      setFormData((prevState) => ({
        ...prevState,
        packages: [..._packages],
        ...additionals,
      }));
    }

    setValue(name, value);
  };

  const fetchSuggestions = async (_search) => {
    if (_search) {
      const results = await provider.search({query: _search});
      setSuggestions(results);
    } else {
      setSuggestions([]);
    }
  };

  const debouncedFetchSuggestions = useCallback(_debounce(fetchSuggestions, 500), []);

  const handleSearchChange = (event) => {
    const keyword = event?.target?.value;
    setSearch(keyword);
    debouncedFetchSuggestions(keyword);
  };

  const handleSelect = (suggestion) => {
    const {x, y, label} = suggestion;
    setMarker({lat: y, lng: x, label});
    setSuggestions([]);

    setFormData((prevState) => ({
      ...prevState,
      latitude: y,
      longitude: x,
      address: label,
    }));

    setValue('latitude', y);
    setValue('longitude', x);
    setValue('address', label);
    clearErrors('latitude');
    clearErrors('longitude');
    clearErrors('address');
  };

  const {isFormDisabled} = useMemo(() => {
    const isFormDisabled = locationState?.isEdit && eventDetail?.status === 'active';
    return {isFormDisabled};
  }, [locationState, eventDetail]);


  const getErrorMessage = (key, name = null) => {
    if (!errors || !errors[key]) return;

    if (!name) {
      name = _capitalize(key.replace('_', ' '));
    }

    return errors[key] ? errors[key]?.replace(key, name) : '';
  };

  const getErrorForm = (key, name = null) => {
    if (!errorForms || !errorForms[key]) return;

    if (!name) {
      name = _capitalize(key.replace('_', ' '));
    }

    return errorForms[key] ? errorForms[key]?.message?.replace(key, name) : '';
  };

  const handleEditorChange = (newValue) => {
    setValue('description', newValue);
    const isEmpty = !(new DOMParser().parseFromString(newValue || '', 'text/html')?.body?.textContent?.trim()?.length);
    if (!isEmpty) clearErrors('description');
  };

  const getErrorPackage = (index, key, name = null) => {
    if (!errorForms?.packages || !errorForms?.packages[index] || !errorForms?.packages[index][key]) return;

    if (!name) {
      name = _capitalize(key.replace('_', ' '));
    }

    return errorForms?.packages[index][key]?.message?.replace(`packages[${index}].${key}`, name);
  };

  useEffect(() => {
    window.history.replaceState({}, '');
    setImgPreview(null);
    clearErrors();
    reset();
  }, [clearErrors, reset]);

  useEffect(() => {
    if (locationState?.id && locationState?.isEdit) {
      dispatch(getEventDetail(locationState.id));
    } else {
      history.push('/event-management/create');
    }
  }, [history, locationState, dispatch]);

  useEffect(() => {
    if (eventDetail?.id && eventDetail.id === locationState.id) {
      const updateForm = {
        title: eventDetail?.title,
        date_start: eventDetail?.date_start,
        date_end: eventDetail?.date_end,
        expired_registration: eventDetail?.expired_registration,
        description: eventDetail?.description,
        type: eventDetail?.type,
        payment_type: eventDetail?.payment_type,
        maximum_participants: eventDetail?.maximum_participants,
        address: eventDetail?.location?.address,
        latitude: eventDetail?.location?.lat,
        longitude: eventDetail?.location?.long,
        packages: eventDetail?.package ? [...eventDetail.package] : [],
        events_img: eventDetail?.photo,
      };

      setFormData({...updateForm});
      setImgPreview(getImageUrl(eventDetail?.photo));

      Object.keys(updateForm).map((item) => {
        if (item === 'maximum_participants') {
          return setValue(item, Number(updateForm[item]));
        } else {
          return setValue(item, updateForm[item]);
        }
      });

      eventDetail?.package && eventDetail.package.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          setValue(`packages[${index}][${key}]`, ['price', 'maximum_participants', 'index'].includes(key) ? Number(item[key]) : item[key]);
        });
      });

      setMarker({
        lat: eventDetail?.location?.lat,
        lng: eventDetail?.location?.long,
        label: eventDetail?.location?.address,
      });
    }
  }, [eventDetail, locationState, setValue]);

  return (
    <>
      <Box className={classes.topBox}>
        <IconButton onClick={redirectToEventDashboard}>
          <ArrowBack />
        </IconButton>

        <Typography
          style={{
            fontWeight: 700,
            fontSize: '18px',
          }}
        >
          Event List
        </Typography>
      </Box>

      <Box
        style={{
          borderRadius: '16px',
          backgroundColor: '#FFF',
          border: '1px solid #EBEBED',
        }}
      >
        <Box className={`${classes.formContainer} ${classes.formTitle}`}>
          <Typography style={{fontSize: '16px'}}>
            Please notice that once you publish the event,
            you cannot update all of the data unless the description field only.
            Please make sure your event date and price is correct before you publish.
          </Typography>
        </Box>

        <Box className={classes.formContainer}>
          <Box className={classes.formTitle}>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: '18px',
                textTransform: 'capitalize',
              }}
            >
              {locationState?.isEdit ? 'Edit ' : 'Create '} Event
            </Typography>

            <Box>
              {!locationState?.isEdit || eventDetail?.status !== 'active' ?
                <MButton
                  type="button"
                  onClick={handleSubmit((data) => onSubmit(data, 'draft'))}
                  className={`${classes.btnHeader} ${classes.btnSaveDraft}`}
                  label="Save Draft"
                  loading={isLoading}
                /> : null}

              <MButton
                type="button"
                onClick={handleSubmit((data) => onSubmit(data, 'publish'))}
                className={`${classes.btnHeader} ${classes.btnPublish}`}
                label={isFormDisabled ? 'Update' : 'Publish Event'}
                loading={isLoading}
              />
            </Box>
          </Box>

          <form autoComplete="off">
            <Grid container>
              <MInput
                {...register('title')}
                label="Event Name *"
                variant="outlined"
                value={getValues('title')}
                onChange={handleInputChange}
                error={getErrorForm('title', 'Event Name') || getErrorMessage('title', 'Event Name')}
                disabled={isFormDisabled}
              />
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={4}>
                <MDatepicker
                  className={classes.formControl}
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  name="date_start"
                  label="Start Date *"
                  value={getValues('date_start')}
                  placeholder="dd/mm/yyyy"
                  minDate={new Date()}
                  fullWidth
                  onDateChange={handleDateChange}
                  error={getErrorForm('date_start', 'Start Date') || getErrorMessage('date_start', 'Start Date')}
                  disabled={isFormDisabled}
                />
              </Grid>

              <Grid item xs={4}>
                <MDatepicker
                  className={classes.formControl}
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  name="date_end"
                  label="End Date *"
                  value={getValues('date_end')}
                  placeholder="dd/mm/yyyy"
                  minDate={formData?.date_start}
                  fullWidth
                  onDateChange={handleDateChange}
                  error={getErrorForm('date_end', 'End Date') || getErrorMessage('date_end', 'End Date')}
                  disabled={isFormDisabled}
                />
              </Grid>

              <Grid item xs={4}>
                <MDatepicker
                  className={classes.formControl}
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  name="expired_registration"
                  label="Maximum Registration Date *"
                  placeholder="dd/mm/yyyy"
                  value={getValues('expired_registration')}
                  minDate={new Date()}
                  maxDate={formData?.date_start}
                  fullWidth
                  onDateChange={handleDateChange}
                  error={getErrorForm('expired_registration', 'Maximum Registration Date') || getErrorMessage('expired_registration', 'Maximum Registration Date')}
                  disabled={isFormDisabled}
                />
              </Grid>
            </Grid>

            <Grid>
              <MSelect
                {...register('type')}
                classNameFC={classes.formControl}
                label="Event Type *"
                endAdornment={
                  (<IconButton className={classes.searchIcon} position="end">
                    <Tooltip title={'Public means your event will be visible to all of Pagii mobile user across your organization. Private means you have to share the event link manually'}>
                      <HelpOutline fontSize="medium" />
                    </Tooltip>
                  </IconButton>)
                }
                keyPair={['id', 'name']}
                options={eventTypeOptions}
                onChange={handleInputChange}
                value={getValues('type')}
                error={getErrorForm('type', 'Event Type') || getErrorMessage('type', 'Event Type')}
                disabled={isFormDisabled}
                shrink
              />
            </Grid>

            <Grid>
              <Grid style={{marginTop: '16px'}}>
                <Typography>Description *</Typography>
              </Grid>

              <Grid>
                <div className={`create-event-editor-wrapper ${errors?.description || errorForms?.description ? classes?.editorWrapperError : classes?.editorWrapper}`}>
                <Grid item xs={11}>
                  <MyEditor
                    value={getValues('description') || (locationState?.isEdit ? eventDetail?.description : null)}
                    onChange={(value) => handleEditorChange(value)}
                  />
                </Grid>

                <Grid item xs={1} ver>
                  <IconButton className={classes.searchIcon} position="end">
                    <Tooltip title={'Describe information of your event including contact info or social media. You can also attach a link of the event'}>
                      <HelpOutline fontSize="medium" />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </div>

              {errors?.description || errorForms?.description ?
                <FormHelperText style={{color: 'red'}}>
                  {getErrorForm('description') || getErrorMessage('description')}
                </FormHelperText> : null}
              </Grid>
            </Grid>

            <Grid style={{marginBottom: '16px'}}>
              <MInput
                type="file"
                classNameFC={classes.fileInput}
                label="Photo *"
                name="events_img"
                inputProps={{accept: SUPPORTED_FILE_FORMATS.join(',')}}
                onChange={handleInputFile}
                error={getErrorForm('events_img', 'Photo') || getErrorMessage('events_img', 'Photo')}
              />

              <span
                style={{
                  fontStyle: 'italic',
                  fontSize: '12px',
                  color: '#656464',
                }}
              >
                Must be jpg/jpeg/png with minimum resolution 1200 x 800 (Max. File Size 5 MB)
              </span>
            </Grid>

            <Grid style={{marginBottom: '16px'}}>
              {(locationState?.isEdit && eventDetail?.photo) || imgPreview ?
                <img
                  loading="lazy"
                  alt={eventDetail?.title || 'User uploaded event banner'}
                  src={imgPreview || getImageUrl(eventDetail?.photo)}
                  style={{borderRadius: '8px', height: '200px'}}
                /> :
              <span className={classes?.imgPreviewLabel}>
                Uploaded photo will be shown here
              </span>}
            </Grid>

            <Divider />

            <Box
              sx={{
                marginTop: '16px',
                marginBottom: '16px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <Typography className={classes.subTitle}>Location</Typography>
                </Grid>

                <Grid item>
                  <Tooltip title={'If you can not found the exact location from the search bar, please fill in the adress field and latitude longitude manually'}>
                    <HelpOutline fontSize="medium" />
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <MInput
                    type="search"
                    label="Search"
                    name="search"
                    placeholder="Search keyword"
                    variant="standard"
                    value={search}
                    onChange={handleSearchChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton className={classes.searchIcon} position="end">
                          <Search />
                        </IconButton>
                      ),
                    }}
                    disabled={isFormDisabled}
                  />
                </Grid>

                <Grid item xs={4}>
                  <MInput
                    {...register('latitude')}
                    type="text"
                    label="Latitude *"
                    placeholder="Latitude"
                    onChange={handleInputChange}
                    value={getValues('latitude')}
                    error={getErrorForm('latitude') || getErrorMessage('lat', 'Latitude')}
                    disabled={isFormDisabled}
                  />
                </Grid>

                <Grid item xs={4}>
                  <MInput
                    {...register('longitude')}
                    type="text"
                    label="Longitude *"
                    placeholder="Longitude"
                    onChange={handleInputChange}
                    value={getValues('longitude')}
                    error={getErrorForm('longitude') || getErrorMessage('long', 'Longitude')}
                    disabled={isFormDisabled}
                  />
                </Grid>
              </Grid>

              {!suggestions?.length ? null : (
                <Grid item xs={12}>
                  <ul style={{listStyleType: 'none', padding: 0, border: '1px solid #ccc'}}>
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleSelect(suggestion)}
                        style={{cursor: 'pointer', padding: '5px', borderBottom: '1px solid #000'}}
                      >
                        {suggestion.label}
                      </li>
                    ))}
                  </ul>
                </Grid>
              )}

              <Grid item>
                <MInput
                  {...register('address')}
                  type="text"
                  label="Address *"
                  value={getValues('address')}
                  onChange={handleInputChange}
                  error={getErrorForm('address') || getErrorMessage('address')}
                  disabled={isFormDisabled}
                />
              </Grid>

              <LocationPicker position={marker} />
            </Box>

            <Divider />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.subTitle}>
                  Event Package
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <MSelect
                  {...register('payment_type')}
                  classNameFC={classes.formControl}
                  label="Payment Type *"
                  keyPair={['id', 'name']}
                  options={packageOptions}
                  onChange={handleInputChange}
                  value={getValues('payment_type')}
                  error={getErrorForm('payment_type') || getErrorMessage('payment_type')}
                  disabled={isFormDisabled}
                  shrink
                />
              </Grid>

              <Grid item xs={4}>
                <MInput
                  {...register('maximum_participants')}
                  type="number"
                  label="Maximum Participants *"
                  placeholder="10"
                  onChange={handleInputChange}
                  value={getValues('maximum_participants')}
                  disabled={formData?.payment_type === 'paid' || isFormDisabled}
                  error={getErrorForm('maximum_participants') || getErrorMessage('maximum_participants')}
                />
              </Grid>

              {formData?.payment_type === 'paid' && (!locationState?.isEdit || eventDetail?.status !== 'active') ?
                <Grid container justifyContent="flex-end" style={{marginBottom: '16px'}}>
                  <MButton
                    color="primary"
                    size="normal"
                    label="Add More Package"
                    onClick={handleAddPackage}
                  />
                </Grid> : null}

              {formData?.payment_type !== 'paid' ? null : fields?.map((item, index) => (
                <PaymentPackage
                  getValues={getValues}
                  isFirst={!index}
                  register={register}
                  key={item.id}
                  packageKey={index}
                  classes={classes}
                  handleChange={handlePackageChange}
                  handleDelete={handleDeletePackage}
                  disabled={isFormDisabled}
                  getError={getErrorPackage}
                />
              ))}
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default withStyles(styles)(CreateEvent);
