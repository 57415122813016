/* eslint-disable max-len */
export default {
  AUTH: {
    LOGIN: '/auth/login',
    LOGIN_SSO: '/auth/login-sso',
    VALIDATE_SSO: '/sso-whitelist/validate',
    LOGOUT: '/auth/logout',
    LOGOUT_SSO: '/auth/logout-sso',
    GENERATE_TOKEN: '/auth/generate-token',
  },
  ATTENDANCE: {
    HISTORY: '/attd/history',
    DETAILS: '/attd/', // ID required
    EXPORT: '/attd/export',
    IMPORT: '/attd/import',
    DOWNLOAD_IMPORT_TEMPLATE: '/attd/download-template',
    NO_RECORD: '/attd/no-record',
  },
  EVENT_MANAGEMENT: {
    CREATE: '/event',
    ANNOUNCEMENT: '/event/announcement/create',
    UPDATE: '/event/:id/update', // ID required
    UPDATE_VOUCHER: '/event/:id/voucher', // ID required
    REQUEST_CANCEL: '/event/:id/cancel-request', // ID required
    CANCEL_APPROVAL: '/event/:id/cancel-approval', // ID required
    BLOCK: '/event/:id/blocked', // ID required
    GET: '/event',
    GET_DETAIL: '/event/', // ID required,
    GET_PARTICIPANTS: '/event/participant/list',
    GET_QR: '/event/qrcode/data',
    GENERATE_QR: '/event/qrcode/generate',
  },
  FRAUD: {
    HISTORY: '/attd/fraud',
    DETAILS: '/attd/', // ID required
    EXPORT: '/attd/export',
    NO_RECORD: '/attd/no-record',
    CHANGE_STATUS: '/attd/update/', // ID required
  },
  USER: {
    PROFILE: '/profile/', // ID Required
    ALL: '/user/shift-user',
    ALL_ADMIN: '/user/admin',
    MANAGER: '/user',
    MANAGER_ID: '/user/',
    ADD: '/user',
    ADDNEW: '/user/create',
    ADD_ADMIN: '/user/admin/create',
    UPDATE_ADMIN: '/user/admin/update/',
    DELETE: '/user/', // ID required
    UPDATE: '/user/', // ID required
    UPDATE_SUPERADMIN: '/user/update/', // ID required
    ROLES: '/role',
    JOBS: '/job-title',
    CHECK_STATUS: '/user/check-status',
    SYNC_ADVANTECH: '/user/sync',
    CANCEL_SYNC: '/user/sync/cancel',
    EXPORT: '/user/export',
    IMPORT: '/user/import',
    READ_NOTIF: '/user/notification/read',
    CANCEL_IMPORT: '/user/import/cancel',
    DOWNLOAD_TEMPLATE: '/user/download-excel-template',
    CREATE_JOB: '/user/job',
    ADD_ADJUSTMENT_LEAVE: '/leave-user',

    BANK: '/bank',
    DETAIL: '/user/', // ID required
    UPDATE_PERSONAL: '/user/personal/', // ID required
    UPDATE_EMPLOYMENT: 'user/employment/', // ID required
    UPDATE_REMOTE: '/user/limit-remote-working/',
    UPDATE_ACCOUNT: '/user/account/',

    CONTRACT: '/contract/', // ID required
    ADD_CONTRACT: '/contract',
    UPDATE_CONTRACT: '/contract/', // ID required
    UPDATE_CONTRACT_STATUS: '/contract/employment-status/', // ID required
    UPDATE_EMPLOYEE_STATUS: '/user/status/', // ID required
    DELETE_CONTRACT: '/contract/', // ID required

    UPDATE_DEDUCTED: '/leave/deductive/', // ID Required
    UPDATE_HISTORY: '/leave-history/user/',
    LEAVE_HISTORY: '/paid-leave/history/',
    USER_PERSONAL_LEAVE: '/leave-user/',
    UPDATE_FCM_TOKEN: '/user/fcmtoken',
  },
  PARTNER: {
    GET: '/partner',
    LIST: '/partner/list',
    GET_ONE: '/partner/', // ID required
    RESEND: '/partner/resend-email/', // ID required
    GET_PROFILE: 'partner/profile',
    ADD: '/partner',
    UPDATE: '/partner/', // ID required
    DELETE: '/partner/', // ID required
    TOGGLE: '/partner/status/', // ID required
    REJECT: '/approval/organization',
    APPROVE: '/approval/organization',
    GET_MEMBER: '/organization/partner',
  },
  SSO_WHITELIST: {
    GET: '/sso-whitelist',
    GET_ONE: '/sso-whitelist/', // ID required
    ADD: '/sso-whitelist',
    DELETE: '/sso-whitelist/', // ID required
    UPDATE: '/sso-whitelist/', // ID required
    TOGGLE: '/sso-whitelist/status/', // ID required
    REGENERATE: '/sso-whitelist/generate/', // ID required
  },
  WITHDRAW: {
    GET: '/withdrawal',
    GET_ONE: '/withdrawal/',
    UPDATE: '/withdrawal/',
    REJECT: '/withdrawal/',
  },
  MASTER_INVENTORY: {
    ALL: '/inventory-master', // (GET)
    DETAIL: '/inventory-master/', // (GET)
    CREATE: '/inventory-master', // (POST) ID required
    DELETE: '/inventory-master/', // (DELETE) ID required
    UPDATE: '/inventory-master/', // (PUT) ID required
    EXPORT: '/inventory-master/export',
    CATEGORY: 'inventory-master/category',

    DELETE_IMAGE: 'inventory-history/delete-image/', // (DELETE) ID required
    CONDITION_HISTORY: '/inventory-history',
    MOVEMENT_HISTORY: '/inventory-movement',
    CONDITION_HISTORY_DETAIL: '/inventory-history/', // (GET) ID required
    CREATE_CONDITION_HISTORY: '/inventory-history', // (POST) ID required
    UPDATE_CONDITION_HISTORY: '/inventory-history/', // (PUT) ID required
    DELETE_CONDITION_HISTORY: '/inventory-history/', // (DELETE) ID required
  },
  OFFICE_INVENTORY: {
    ALL: '/office-inventory/list', // (GET)
    DETAIL: '/office-inventory/', // (GET)
    COMPLETE: '/office-inventory/change-status/', // (PUT) ID required
    ACCEPT: '/office-inventory/change-status/', // (PUT) required
    DENY: '/office-inventory/change-status/', // (PUT) required
    EXPORT: '/office-inventory/export/', // ID required
  },
  CASH_ADVANCE: {
    ALL: '/cash-advance/history', // (GET)
    DETAIL: '/cash-advance/', // (GET)
    COMPLETE: '/cash-advance/update/', // (PUT) ID required
    ACCEPT: '/cash-advance/update/', // (PUT) required
    DENY: '/cash-advance/update/', // (PUT) required
    EXPORT: '/cash-advance/export/', // ID required
  },
  ORGANIZATION: {
    ALL: '/organization',
    ALL_USER: '/user/no-org',
    ADD: '/organization',
    ADD_CHILD: '/organization/childs/',
    DETAIL: '/organization/', // ID required
    DELETE: '/organization/', // ID required
    DELETE_CHILD: '/organization/childs',
    UPDATE: '/organization/', // ID required
    INVITATION: '/organization/childs/invite',
    ACTIVATE_PARENT_INVITATION: '/organization/activate',
    ACTIVATE_INVITATION: '/organization/childs/activate',
    ACTIVATE_PARENT: '/organization/activate',
    CONFIRM_CHILD_INVITATION: '/organization/childs/invite/confirm',
    ROLES: '/organization',
    JOBS: '/organization',
    CHECK_STATUS: '/organization/check-status',
    TYPES: '/organization-type',
    TYPES_NO_AUTH: '/organization-type',
    SHOW_LOGO: '/organization/logo',
    ANALYTICS: '/organization/', // ID required
    CHECK_ODOO: '/organization/odoo/check-connection',
    EXPORT: '/organization/export',
    ACCEPT_TERMS: '/trem-and-condition',
    UPDATE_MOBILE_FEATURE_SETTINGS: '/organization/mobile-features-setting',
    LIST: '/organization-list',
    ASSIGN_ORG: '/user/assign-org',
  },
  PAYMENT: {
    ALL: '/payment-confirmation',
    ADD: '/payment-confirmation',
    DELETE: '/payment-confirmation/', // ID required
    UPDATE: '/payment-confirmation/change-status/', // ID required
    BY_ORG: '/payment-history',
    ANALYTICS: '/payment-confirmation/analytics',
    ANALYTICS_DETAILS: '/payment-confirmation/analytics/details',
  },
  GENERAL_SETTING: {
    ALL: '/general-setting',
    UPDATE: '/general-setting',
    FETCH_QR_SETTINGS: '/attd/qrcode/print',
    GENERATE_LINK: '/attd/qrcode/url/generate',
    GENERATE_QR: '/attd/qrcode/generate',
    UPDATE_AUTO_REFRESH: '/attd/qrcode/setting',
    CHECK_QR_TOKEN: '/attd/qrcode/',
    PUBLISH: '/push-notif/send-to-org',
    UPLOAD_BANNER: '/general-setting/upload-banner',
    FETCH_BANNER: '/general-setting/banner',
  },
  ROLE: {
    ALL: '/role',
    ADD: '/role',
    DELETE: '/role/', // ID required
    UPDATE: '/role/', // ID required
  },
  UPGRADE: {
    CREATE_BANK_PAYMENT: '/payment-confirmation',
  },
  PAID_LEAVE: {
    HISTORY: '/paid-leave/history',
    DETAILS: '/paid-leave/details/',
    UPDATE: '/paid-leave/update/',
    CHANGE_STATUS: '/paid-leave/change-status/', // ID required
    EXPORT: '/paid-leave/export',
  },
  INVOICE: {
    ALL: '/invoice',
    GENERATE: '/invoice/generate',
    DETAIL: '/invoice/organization/',
    HISTORY: '/invoice/history',
    UPDATE: '/invoice/update/',
    UPDATE_INVOICE: '/organization/invoice/',
    CHECK_AMOUNT: '/invoice/amount/',
    PRICE: '/invoice/price/',
    SELECT: '/invoice/change/',
  },
  APPROVAL: {
    LEAVE_DETAILS: '/paid-leave/details/',
    OVERTIME_DETAIL: '/overtime/details/',
    LEAVE_CONFIRM: '/approval/leave/',
    OVERTIME_CONFIRM: '/approval/overtime/',
    PERMIT_DETAIL: '/permit/details/',
    PERMIT_CONFIRM: '/approval/permit/',
    REIMBURSE_DETAIL: '/reimburse/details/',
    REIMBURSE_CONFIRM: '/approval/reimburse/',
    PAYMENT_DETAIL: '/payment-confirmation/details/',
    PAYMENT_CONFIRM: '/approval/payment-confirmation/',
  },
  REIMBURSE: {
    HISTORY: '/reimburse/history',
    CHANGE_STATUS: '/reimburse/update/', // ID required
    EXPORT: '/reimburse/export', // ID required
  },
  PERMIT: {
    HISTORY: '/permit/history',
    CHANGE_STATUS: '/permit/update/', // ID required
    EXPORT: '/permit/export/', // ID required
  },
  OVERTIME: {
    HISTORY: '/overtime/history',
    CHANGE_STATUS: '/overtime/update/',
    EXPORT: '/overtime/export', // ID required
  },
  JOB_TITLE: {
    ALL: '/job-title',
    BY_ORG: '/job-title/organization',
    ADD: '/job-title',
    DELETE: '/job-title/', // ID required
    UPDATE: '/job-title/', // ID required
    EXPORT: '/job-title/export',
  },
  LEAVE_CONFIG: {
    ALL: '/leave',
    ADD: '/leave',
    DELETE: '/leave/', // ID required
    UPDATE: '/leave/', // ID required
  },
  LEAVE_MANAGEMENT: {
    ALL: '/leave/user',
  },
  LEAVE_MANAGEMENT_DETAIL: {
    DETAIL: '/leave/detail/', // ID required
  },
  MASTER_HOLIDAY: {
    ALL: '/collective-leave/',
    ADD: '/collective-leave',
    DELETE: 'collective-leave/',
    UPDATE: '/collective-leave/',
  },
  COMPANY_TYPE: {
    ALL: '/organization-type',
    DETAILS: '/organization-type', // ID required
    ADD: '/organization-type',
    DELETE: '/organization-type/', // ID required
    UPDATE: '/organization-type/', // ID required
  },
  REGISTER: {
    CREATE: '/register',
    REGISTER_SSO: '/register/sso',
    APPROVAL_SSO: '/register/process-sso',
    APPROVAL: '/register/process',
    APPROVAL_USER: '/register/user/process',
    ANALYTICS: '/register/analytics',
    ANALYTICS_DETAILS: '/register/analytics/details',
  },
  REGISTER_USER: {
    CREATE: '/register/user',
    APPROVAL: '/register/process',
    APPROVAL_USER: '/register/user/process',
    ANALYTICS: '/register/analytics',
    ANALYTICS_DETAILS: '/register/analytics/details',
  },
  ACCOUNT: {
    CHECK_TOKEN: '/auth/change-password',
    UPDATE_PASSWORD: '/auth/change-password',
    FORGOT_PASSWORD: '/auth/forgot-password',
  },
  DASHBOARD: {
    ALL: '/dashboard/attd',
    TODAY: '/dashboard/attd/today',
    YESTERDAY: '/dashboard/attd/yesterday',
    LAST_WEEK: '/dashboard/attd/last-week',
    LAST_MONTH: '/dashboard/attd/last-month',
  },
  SHIFT_MANAGEMENT: {
    ALL: '/user/shift', // ID required
    UPDATE: '/user/shift/update', // Shift ID and Employee ID required in request body
  },
  XENDIT: {
    GET_TOKEN: 'xendit/invoice',
    CHECK_TOKEN: 'xendit/invoice/status',
  },
  LEADERBOARD: {
    GET_TODAY: '/dashboard/attd/employee/today',
    GET_MONTH: '/dashboard/attd/employee/',
  },
  REGISTRATION: {
    LIST: '/register',
    CHANGE_STATUS: '/register/process', // ID required
  },
  FORGOT_PASSWORD: {
    SEND: '/auth/forgot-password',
  },
  DIVISION: {
    ALL: '/division',
    BY_ORG: '/division/organization',
    APPROVAL: '/division/approval-division/',
    ADD: '/division',
    DELETE: '/division/', // ID required
    UPDATE: '/division/', // ID required
  },
  APPROVAL_ORDER: {
    GET: '/approval',
    DETAIL: '/approval/', // ID required
    UPDATE: '/approval/', // ID required
    MASTER: '/user/master',
    APPROVAL: '/user/approval',
  },
  BANK: {
    GET: '/bank',
  },
  NOTIFICATION: {
    GET: '/announcement/last-week?type=personal',
    READ: '/announcement/read/',
  },
  EXPORT_ACTIVITY: {
    GET: '/excel',
  },
};
