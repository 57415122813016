/* eslint-disable react/display-name */
import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Grid, IconButton, Paper, withStyles} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {getParticipants} from '../../../redux/eventManagement/eventManagement.actions';
import {Datatable} from '../../../components';
import {MButton, MInput, MSelect} from '../../../components/form';
import {Refresh as ResetIcon, Search as SearchIcon} from '@material-ui/icons';
import styles from './styles';
import {debounce as _debounce} from 'lodash';
import moment from 'moment';
import {convDate} from '../../../helpers';

const initialFilter = {
  pagination: true,
  page: 1,
  limit: 10,
  keyword: null,
  start_date: null,
  end_date: null,
};

const columns = [
  {name: 'name', label: 'Name', display: true},
  {name: 'email', label: 'Email', display: true},
  {
    name: 'package',
    label: 'Package',
    display: true,
    customBodyRender: (value) => <span style={{textTransform: 'capitalize'}}>{!value ? 'free' : value}</span>,
  },
  {
    name: 'voucher_code',
    label: 'Voucher',
    display: true,
    customBodyRender: (value) => value || '-',
  },
  {name: 'phone', label: 'Phone', display: true},
  {
    name: 'gender',
    label: 'Gender',
    display: true,
    customBodyRender: (value) => <span style={{textTransform: 'capitalize'}}>{value || '-'}</span>,
  },
  {name: 'job_title', label: 'Job Title', display: true},
  {name: 'company', label: 'Company', display: true},
  {
    name: 'createdAt',
    label: 'Joined At',
    display: true,
    customBodyRender: (value) => {
      if (!value || !value?.length) return '-';

      return <Button
        variant="outlined"
        size="small"
        style={{whiteSpace: 'nowrap'}}
        >
          {convDate(value, 'DD-MM-YYYY HH:mm:ss')}
      </Button>;
    },
  },
  {
    name: 'history',
    label: 'Check-In',
    display: true,
    customBodyRender: (value) => {
      if (!value || !value?.length) return '-';

      const time = value?.find((item) => {
        return item?.method === 'checkin';
      })?.time;

      return <Button
        variant="outlined"
        size="small"
        style={{whiteSpace: 'nowrap'}}
        >
          {convDate(time, 'DD-MM-YYYY HH:mm:ss')}
      </Button>;
    },
  },
  {
    name: 'history',
    label: 'Check-Out',
    display: true,
    customBodyRender: (value) => {
      if (!value || !value?.length) return '-';

      const findLast = value?.findIndex((item) => item?.method === 'checkout');

      if (findLast === -1) return '-';

      return <Button
          variant="outlined"
          size="small"
          style={{whiteSpace: 'nowrap'}}
          >
            {convDate(value[findLast]?.time, 'DD-MM-YYYY HH:mm:ss')}
        </Button>;
    },
  },
];

function EventParticipants({classes}) {
  const dispatch = useDispatch();
  const [filterState, setFilterState] = useState({...initialFilter});
  const [filterDateOptions, setFilterDateOptions] = useState([{id: 'all', name: 'All'}]);
  const [filterVoucherOptions, setFilterVoucherOptions] = useState([{id: 'all', name: 'All'}]);
  const {eventDetail, isLoading, participants: data} = useSelector((state) => state.eventManagement);

  const fetchParticipants = useCallback((params = initialFilter, _filter = null) => {
    const newParams = {
      ...initialFilter,
      ...params,
      keyword: _filter?.keyword || params?.keyword,
      start_date: _filter?.start_date === 'all' ? null : _filter?.start_date,
      end_date: _filter?.end_date === 'all' ? null : _filter?.end_date,
      event_id: eventDetail?.id,
    };

    if (params?.sortBy) {
      delete newParams.sortBy;
      newParams.order = params.sortBy;
      newParams.sort = params.order?.toUpperCase();
    }

    dispatch(getParticipants(newParams));
  }, [dispatch, eventDetail]);

  const handleApplyFilter = useCallback(_debounce(fetchParticipants, 300), []);

  const handleResetFilter = () => {
    setFilterState({...initialFilter});
    fetchParticipants(initialFilter);
  };

  const handleFilterChange = (event) => {
    const {name, value} = event?.target || {};
    let newState = {};

    if (name === 'start_date') {
      newState = {
        ...filterState,
        [name]: value ? value : null,
        end_date: value ? value : null,
      };
    } else {
      newState = {
        ...filterState,
        [name]: value ? value : null,
      };
    }

    if (name === 'voucher_code') {
      newState = {
        ...newState,
       [name]: value === 'all' ? null : value,
      };
    }

    setFilterState({...newState});
    handleApplyFilter(newState);
  };

  const handleFilterDateOptions = useCallback((_detail) => {
    const dateStart = moment(_detail?.date_start);
    const dateEnd = moment(_detail?.date_end);

    if (dateStart.startOf('day') === dateEnd.startOf('day')) {
      setFilterDateOptions((prevState) => ([
        ...prevState,
        {
          id: dateStart.format('DD MMMM YYYY'),
        }]
      ));
    } else {
      const diff = dateEnd.startOf('day').diff(dateStart.startOf('day'), 'days');
      const loopStart = dateStart.clone();
      const newFilterDateOptions = [];

      for (let i = 0; i <= diff; i++) {
        newFilterDateOptions.push({
          id: loopStart.format('YYYY-MM-DD'),
          name: loopStart.format('D MMMM YYYY'),
        });

        loopStart.add(1, 'days');
      }

      setFilterDateOptions((prevState) => ([...prevState, ...newFilterDateOptions]));
    }
  }, []);

  const handleFilterVoucherOptions = useCallback((_detail) => {
    const options = _detail?.voucher?.map((item) => ({id: item?.code, name: item?.code})) || [];
    setFilterVoucherOptions((prevState) => ([...prevState, ...options]));
  }, []);

  useEffect(() => {
    fetchParticipants();
    handleFilterDateOptions(eventDetail);
    handleFilterVoucherOptions(eventDetail);
  }, [eventDetail, fetchParticipants, handleFilterDateOptions, handleFilterVoucherOptions]);

  return (
    <Box>
      <Paper className={classes.paper}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <MInput
              autoFocus
              fullWidth
              variant="standard"
              name="keyword"
              label="Search"
              placeholder="Search keyword"
              onChange={handleFilterChange}
              value={filterState?.keyword || ''}
              InputProps={{
                endAdornment: <IconButton position="end" className={classes.searchIcon}>
                  <SearchIcon />
                </IconButton>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <MSelect
              shrink={true}
              fullWidth
              variant="standard"
              name="start_date"
              label="Date"
              keyPair={['id', 'name']}
              options={filterDateOptions}
              onChange={handleFilterChange}
              value={filterState?.start_date || filterDateOptions[0].id}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <MSelect
              shrink={true}
              fullWidth
              variant="standard"
              name="voucher_code"
              label="Voucher"
              keyPair={['id', 'name']}
              options={filterVoucherOptions}
              onChange={handleFilterChange}
              value={filterState?.voucher_code || filterVoucherOptions[0].id}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <MButton
              label="Reset"
              color="primary"
              icon={<ResetIcon />}
              onClick={handleResetFilter}
            />
          </Grid>
        </Grid>
      </Paper>

      <Datatable
        title="Participants"
        dataSource={data?.participants}
        total={data?.paginator?.itemCount}
        page={data?.paginator?.page || 1}
        columns={columns}
        hideActions={true}
        creatable={false}
        loading={isLoading}
        handleReload={(params) => fetchParticipants(params, filterState)}
      />
    </Box>
  );
}

export default withStyles(styles)(EventParticipants);
